<template>
  <b-card class="text-center standar-card-bg full-width min-height-debit-toc">
    <b-card-text>
      <b-container class="max-width-debit-card" fluid align-content="center">
        <b-row fluid class="pb-4">
          <b-col class="text-center">
            <strong>
              <span>
                Por favor, lea con atención los términos y condiciones
              </span>
            </strong>
          </b-col>
        </b-row>
        <b-row fluid>
          <b-col>
            <div
              class="debit-toc-scroll"
              ref="debit-toc-scroll"
              @scroll="handleScroll"
            >
              <span class="title-debit-toc-scroll pt-4 pb-4"
                >Términos y condiciones Bithan</span
              >
              <span class="body-debit-toc-scroll" v-html="toc_text"> </span>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!form.data.all_readed">
          <b-col class="form-help" v-html="toc_detail"> </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col class="text-left pt-3">
            <b-form-checkbox
              v-model="form.data.no_politic"
              class="bithan-color"
            >
              <span class="leyenda-terminos-condiciones">
                No soy una persona políticamente expuesta
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-left pt-3">
            <b-form-checkbox
              v-model="form.data.licit_person"
              class="bithan-color"
            >
              <span class="leyenda-terminos-condiciones">
                El origen de mis fondos corresponde a actividades lícitas
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row fluid class="pt-4"
          ><b-col
            ><span class="boton-deposito">
              <b-button
                variant="bithan-button"
                @click="goToDebitForm"
                :disabled="
                  !(
                    form.data.no_politic &&
                    form.data.licit_person &&
                    form.data.all_readed
                  )
                "
              >
                SIGUIENTE
              </b-button>
            </span></b-col
          ></b-row
        >
        <b-row fluid class="pt-4"
          ><b-col
            ><span class="boton-cancelar"
              ><b-button variant="bithan-link-button" @click="goToHome"
                >CANCELAR</b-button
              ></span
            ></b-col
          ></b-row
        >
        <b-col class="pt-4">
          <stepper  :filled="currentStep" :size="maxSteps"/>
        </b-col>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {TOC} from './static-toc'
import stepsMixin from '@/mixins/stepsMixin'
import { mapGetters } from 'vuex'

export default {
  name: "TerminosCondiciones",
  components: {
    Stepper: () => import("@/views/Components/Giftcards/Stepper")
  },
  mixins: [stepsMixin],
  data() {
    return {
      form: {
        data: { no_politic: false, licit_person: false, all_readed: false }
      },
      toc_text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget tempor magna. Vivamus in lectus at neque tristique lobortis. Morbi porttitor, purus ut faucibus venenatis, diam risus <strong>lobortis augue</strong>, eget gravida nisl elit eget ipsum. Donec viverra vestibulum felis, sed consectetur justo rhoncus a. In ac nisl vel dui dignissim mattis. Morbi rhoncus congue turpis eget commodo. Duis purus est, commodo sit amet elementum sit amet, tristique id velit. Nunc cursus ipsum dui. Donec adipiscing congue enim eget viverra.</p> <p>Fusce sed felis sed tortor placerat mattis eu eget risus. Fusce id sem vel ligula luctus malesuada. Vivamus nec erat in risus suscipit gravida vel egestas magna. Vestibulum molestie lectus sapien. Aliquam in egestas risus. Proin a justo eget tortor tincidunt blandit. In nec risus diam. Phasellus <string>vestibulum</strong> faucibus <strong>sodales</strong>. Duis vitae sapien mi, et laoreet enim. Sed interdum tellus ullamcorper risus consequat sit amet vulputate felis vestibulum. Sed sed ante et sapien porta varius.</p> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget tempor magna. Vivamus in lectus at neque tristique lobortis. Morbi porttitor, purus ut faucibus venenatis, diam risus <strong>lobortis augue</strong>, eget gravida nisl elit eget ipsum. Donec viverra vestibulum felis, sed consectetur justo rhoncus a. In ac nisl vel dui dignissim mattis. Morbi rhoncus congue turpis eget commodo. Duis purus est, commodo sit amet elementum sit amet, tristique id velit. Nunc cursus ipsum dui. Donec adipiscing congue enim eget viverra.",
      toc_detail: ""
    }
  },
  async mounted() {
    this.getTXT();
    if (!this.toc_has_scroll()) {
      this.form.data.all_readed = true
    }
  },
  methods: {
    getTXT() {
      this.toc_text = TOC.content
      this.toc_detail = TOC.detail
    },
    goToDebitForm() {
      this.$emit("confirm-step", this.form)
    },
    goToHome() {
      // this.$router.push("/")
      if(this.shouldReload) {
        location.reload();
        return
      }

      this.$router.push({
        name: this.routeByOrigin
      }).catch(()=>{});
    },
    handleScroll(event) {
      const scroll = event.target
      if (scroll.scrollHeight - scroll.clientHeight - scroll.scrollTop  <= 2) {
        this.form.data.all_readed = true
      }
    },
    toc_has_scroll() {
      // verify if toc has scroll at created
      return (
        this.$refs["debit-toc-scroll"].scrollHeight >
        this.$refs["debit-toc-scroll"].clientHeight
      )
    }
  },
  computed: {
    ...mapGetters("user", [
      'routeByOrigin',
      'shouldReload'
    ]),
  }
}
</script>

<style></style>
